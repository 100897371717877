import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Typography as TypographyAnt} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {Enrollment, Status, Student} from '../../lib';
import icon_top from '../../assets/icon_top.svg';
import ToggleIcon from '../../assets/toggle_.svg';
import Logout from '../../assets/logout.svg';
import Pikado from '../../assets/PikaDo-logo-Final-3 3.png';
import PikadoMobile from '../../assets/PikaDoLogoMobile.png';
import './appBar.css';
import AddBtn from '../../assets/Icons/add-btn.svg';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {
  doSignOut,
  fetchSelectedStudent,
  getStudentTodayPlan,
  setSelectedStudent,
} from '../../redux/actions';
import {isLoadingRequest} from '../../redux/actions';

import ArrowDown from '../../assets/down-arrow.svg';
import {SettingsModal} from '../SettingsModal';

const settings = ['Subscription code', 'Sign out'];

const {Text} = TypographyAnt;

function ResponsiveAppBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedInAsPartner = useSelector(
    (state: any) => state.auth.partnerUser,
  );
  // console.log(location.pathname);

  const selectedStudent = useSelector(
    (state: any) => state.student.selectedStudent,
  );

  const selectedEnrollment: Enrollment | null = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );

  const studentsDetails = useSelector(
    (state: any) => state.student.studentsDetails,
  );

  const [isSettingsModalVisible, setIsSettingsModalVisible] =
    React.useState<boolean>(false);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (isLoggedInAsPartner) return;
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (index: number) => {
    setAnchorElNav(null);

    // console.log('SSS');
    if (index === 0) {
      setIsSettingsModalVisible(true);
    } else if (index === 1) {
      //@ts-ignore
      dispatch(doSignOut());
      history.push('/signIn');
    }
  };

  const handleCloseUserMenu = async (s: Student) => {
    setAnchorElUser(null);
    dispatch(isLoadingRequest(true));
    try {
      // @ts-ignore
      await dispatch(setSelectedStudent(s));
      // @ts-ignore
      await dispatch(fetchSelectedStudent());
      // @ts-ignore
      await dispatch(getStudentTodayPlan());
    } catch (error) {
      console.error('Error in student data loading:', error);
    }
  };

  function stringToColor(string: string = '..') {
    let hash = 0;
    let i;
    if (string) {
      /* eslint-disable no-bitwise */
      for (i = 0; i < string?.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = '#';

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    }
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        width: 35,
        height: 35,

        bgcolor: stringToColor(name),
      },
      children: `${
        name?.split(' ') ? name?.split(' ')[0][0] : name?.substring(0, 1)
      }`,
    };
  };

  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottomWidth: 1,
        borderBottomColor: '#F1F1F1',
        borderBottomStyle: 'solid',
        zIndex: 1000,
      }}
      color="primary">
      <Container maxWidth="xl" className="appBar container-fluid">
        <Toolbar disableGutters>
          <img
            onClick={() => {
              if (location?.pathname !== '/home') {
                history.goBack();
              }
            }}
            className="pikado-image   border-1 border-end pe-4 ps-0"
            alt="s"
            src={Pikado}
            style={{
              resize: 'inline',
              width: '12%',
            }}
          />
          <img
            onClick={() => {
              if (location?.pathname !== '/home') {
                history.goBack();
              }
            }}
            className="pikado-image-mobile"
            alt="s"
            src={PikadoMobile}
            style={{
              resize: 'inline',
              marginInlineEnd: 20,
              width: '12%',
            }}
          />
          <div className="welcome-container">
            <Text
              className="welcome-title"
              style={{
                display: 'inline-block',
                fontFamily: 'Roboto',
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 18,
              }}>
              {`Welcome  ${
                selectedStudent?.name ? ', ' + selectedStudent?.name : ''
              }`}
            </Text>
          </div>
          {
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}></Box>
              {!selectedEnrollment?.package?.isFree &&
              selectedEnrollment?.paymentStatus === Status.DONE &&
              (location?.pathname === '/home' || location?.pathname === '/') &&
              selectedStudent ? (
                <Box>
                  <Tooltip title="Your Report">
                    <Link to={`/report/${selectedStudent?.id}`}>
                      <IconButton
                        size="medium"
                        aria-label="report"
                        color="inherit">
                        <img
                          src={icon_top}
                          style={{
                            display: 'inline-block',
                          }}
                          // onClick={() => {}}
                        />

                        <Typography
                          textAlign="center"
                          noWrap
                          style={{
                            fontSize: 16,
                            color: '#40B2F2',
                            fontWeight: 700,
                            marginInline: '10px',
                          }}>
                          <span className="report-title">Report</span>
                        </Typography>
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Box>
              ) : (
                <Box>
                  <Tooltip title="Your Exercises">
                    <Link to={`/home`}>
                      <IconButton
                        size="medium"
                        aria-label="Exercises"
                        color="inherit">
                        <img
                          src={icon_top}
                          style={{
                            display: 'inline-block',
                          }}
                          // onClick={() => {}}
                        />

                        <Typography
                          textAlign="center"
                          noWrap
                          style={{
                            fontSize: 16,
                            color: '#40B2F2',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                            marginInline: '10px',
                          }}>
                          <span className="report-title">Exercises</span>
                        </Typography>
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Box>
              )}
              <Box sx={{flexGrow: 0}}>
                <Tooltip title="settings">
                  <IconButton
                    onClick={handleOpenNavMenu}
                    sx={{p: 0, width: 45, height: 45}}>
                    <Avatar
                      alt="s"
                      src={ArrowDown}
                      sx={{width: 35, height: 35}}
                      style={{
                        marginInline: 8,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{mt: '45px'}}
                  id="menu-settings-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={() => {
                    setAnchorElNav(null);
                  }}>
                  {settings.map((s: string, index: number) => (
                    <MenuItem
                      key={s}
                      onClick={(e) => handleCloseNavMenu(index)}>
                      <Typography
                        textAlign="center"
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 14,
                          color: '#000',
                        }}>
                        {index === 0 ? (
                          <img
                            src={ToggleIcon}
                            style={{
                              display: 'inline-block',
                              width: 20,
                              height: 16,
                              marginInlineEnd: 7,
                            }}
                          />
                        ) : (
                          <img
                            src={Logout}
                            style={{
                              display: 'inline-block',
                              width: 20,
                              height: 16,
                              marginInlineEnd: 7,
                            }}
                            onClick={() => handleCloseNavMenu(1)}
                          />
                        )}
                        {s}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box sx={{flexGrow: 0}}>
                <Tooltip title={isLoggedInAsPartner ? '' : 'profiles'}>
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{p: 0, width: 45, height: 45}}>
                    {selectedStudent?.name ? (
                      <Avatar
                        alt="..."
                        {...stringAvatar(selectedStudent?.name)}
                      />
                    ) : (
                      ''
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{mt: '45px'}}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={() => {
                    setAnchorElUser(null);
                  }}>
                  {studentsDetails.map((s: Student) => (
                    <MenuItem
                      key={s.id}
                      onClick={() => handleCloseUserMenu(s)}
                      disabled={selectedStudent?.id === s.id}>
                      <Typography textAlign="center">{s?.name}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={() => history.push('/whoNeedToLearn')}
                    className="d-flex justify-content-center">
                    <img
                      src={AddBtn}
                      className="img-fluid mb-2 w-50"
                      alt="AddBtn"
                    />
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          }
        </Toolbar>
        {isSettingsModalVisible && (
          <SettingsModal
            closeModal={() => {
              setIsSettingsModalVisible(false);
            }}
          />
        )}
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
