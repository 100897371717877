import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {IntercomProps, useIntercom} from 'react-use-intercom';

function InterComComponent() {
  const {boot, hardShutdown} = useIntercom();
  const auth = useSelector((state: any) => state.auth);
  const user = useSelector((state: any) => state.user);
  const prevLoggedInState = useRef(auth?.isLoggedIn);

  useEffect(() => {
    // Only run the effect if the login state has changed
    if (prevLoggedInState.current !== auth?.isLoggedIn) {
      prevLoggedInState.current = auth?.isLoggedIn;

      if (auth?.isLoggedIn && auth?.user?.attributes?.email && user?.user?.id) {
        const bootProps: IntercomProps = {
          email: auth?.user?.attributes?.email,
          userId: user?.user?.id,
          name: '',
        };

        boot(bootProps);
      } else {
        hardShutdown();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isLoggedIn, auth?.user?.attributes?.email, user?.user?.id]);

  return <div />;
}

// Use React.memo to prevent unnecessary re-renders
const InterCom = React.memo(InterComComponent);

export default InterCom;
