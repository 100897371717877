import {Typography, Button, Tabs} from 'antd';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import checkMark from '../../assets/check-mark-icon.png';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {
  Loader,
  ProgressModal,
  QuestionCard,
  TestCard,
  TestModal,
} from '../../components';

import {ExerciseModal} from '../../components/ExerciseModal';
import {
  AssetType,
  ProgramType,
  QUESTION_TYPE,
  Question,
  Status,
  Test,
} from '../../lib';
import {
  doClearTest,
  doSelectQuestion,
  doSelectTest,
  generateRandomExBasedOnWeakSyllables,
} from '../../redux/actions';
import {Homework} from '../../redux/reducers/questionReducers';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import ResponsiveAppBar from '../../components/Appbar';
import FooterBar from '../../components/Footerbar';
import ReportsHeader from '../../components/ReportsHeader';
import {HomeworkModal} from '../../components/HomeworkModal';

const openAIQuestionAsset: Question = {
  id: '-OPEN-AI',
  createdAt: 'Node.generateCreatedAt()',
  updatedAt: 'Node.generateUpdatedAt()',
  name: 'Daily Reading Paragraph',
  name_i18n: '',
  programType: ProgramType.LANGUAGE,
  summary:
    'Press the below button to generate a reading paragraph depends on your repeated syllables mistakes.',
  summary_i18n: '',
  description:
    'Please press the recording button and read the below sentence in a clear voice.',
  description_i18n: '',
  text: 'selectedSentence',
  asset: {
    __typename: 'Asset',
    id: 'adsss',
    createdAt: '',
    updatedAt: '',
    type: AssetType.PHOTO,
    thumbnailUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    fileUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    title_i18n: '',
    subTitle_i18n: '',
    // position?: number ,
  },
  choices: [],
  keywords: [],
  textAI: 'selectedSentence?.toUpperCase()',
  type: QUESTION_TYPE.READ_SENTENCE,
  __typename: 'Question',
};
type AppDispatch = ThunkDispatch<any, any, AnyAction>;
export const HomePage = () => {
  const dispatch: AppDispatch = useDispatch();
  const teacherPhotoRef = useRef<HTMLDivElement>(null);

  // Redux selectors
  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const todayRandomExercise: Question | null = useSelector(
    (state: any) => state.enrollment.todayRandomExercise,
  );
  const selectedStudent = useSelector(
    (state: any) => state.student.selectedStudent,
  );
  const studentsDetails = useSelector(
    (state: any) => state.student.studentsDetails,
  );
  const studentsPlanMap = useSelector(
    (state: any) => state.enrollment.studentPlan,
  );
  const selectedEnrollment = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );

  // Local state
  const [isError, setIsError] = useState<boolean>(false);
  const [isProgressModalVisible, setIsProgressModalVisible] =
    useState<boolean>(false);
  const [modalState, setModalState] = useState({
    openExerciseModal: false,
    openTestModal: false,
    openHomeworkModal: false,
  });
  const [selectedHomework, setSelectedHomework] = useState<Homework>();
  const [planDay, setPlanDay] = useState<string>();

  // Derived state
  const {openExerciseModal, openTestModal, openHomeworkModal} = modalState;

  // Set modal states with a single function to reduce re-renders
  const setModalVisibility = useCallback(
    (
      modalType: 'openExerciseModal' | 'openTestModal' | 'openHomeworkModal',
      isVisible: boolean,
    ) => {
      setModalState((prevState) => ({
        ...prevState,
        [modalType]: isVisible,
      }));
    },
    [],
  );

  // Format date function - memoized to prevent recreation on each render
  const formatDate = useCallback((date: string) => {
    const dateStr = new Date(date);
    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(dateStr);
  }, []);

  // Check if enrollment is ready - memoized
  const isEnrollmentReady = useCallback(() => {
    if (Object.keys(studentsPlanMap)?.length !== 0 && selectedStudent) {
      return true;
    } else if (
      Object?.keys(studentsPlanMap)?.length !== 0 &&
      selectedStudent &&
      selectedEnrollment?.package.isFree
    ) {
      return true;
    }
    return false;
  }, [studentsPlanMap, selectedStudent, selectedEnrollment?.package.isFree]);

  // Update teacher photo when dependencies change
  useEffect(() => {
    isEnrollmentReady();
    if (teacherPhotoRef?.current) {
      const teacherPhotoUri =
        selectedStudent?.school != null &&
        selectedStudent.school?.classesschool?.[0]?.teacher?.profilePicture
          ?.fileUri != undefined
          ? selectedStudent.school.classesschool[0].teacher.profilePicture
              .fileUri
          : require('../../assets/profile-icon.jpg');

      teacherPhotoRef.current.style.backgroundImage = `url(${teacherPhotoUri})`;
      teacherPhotoRef.current.style.backgroundSize = 'cover';
      teacherPhotoRef.current.style.backgroundPosition = 'center';
    }
  }, [selectedEnrollment, studentsPlanMap, selectedStudent]);

  // Memoized card rendering function
  const renderCard = useCallback(
    (test: Test, index: number) => {
      const isBlurredForFreePackage =
        selectedEnrollment?.package?.isFree ||
        selectedEnrollment?.paymentStatus !== Status.DONE;

      if (test?.questions?.length! > 1 && test?.type !== 'QUIZ') {
        return (
          <TestCard
            test={test}
            index={index}
            key={`selectedMapId_${index}`}
            isBlur={isBlurredForFreePackage && index > 1}
            onClick={() => {
              dispatch(doSelectTest(test));
              setModalVisibility('openTestModal', true);
            }}
          />
        );
      } else {
        return (
          <div
            className=""
            style={{display: 'contents'}}
            key={`selectedMapId_${index}`}>
            <QuestionCard
              isBlur={isBlurredForFreePackage && index > 0}
              //@ts-ignore
              question={test}
              index={index}
              onClick={() => {
                if (test?.questions?.length === 1) {
                  dispatch(doSelectQuestion(test.questions[0]));
                } else {
                  dispatch(doSelectQuestion(test));
                }
                setModalVisibility('openExerciseModal', true);
              }}
            />
          </div>
        );
      }
    },
    [selectedEnrollment, dispatch, setModalVisibility],
  );

  // Memoized today's plan rendering
  const renderTodayPlan = useCallback(() => {
    const items = studentsPlanMap[selectedStudent?.id];
    if (!items) return null;

    const videoItems = items
      ?.filter((item: Test) => item.asset.type === 'VIDEO')
      .sort((a: Test, b: Test) => (a?.name ?? '').localeCompare(b?.name ?? ''));

    const otherItems = items
      ?.filter((item: Test) => item.asset.type !== 'VIDEO')
      .sort((a: Test, b: Test) => (a?.name ?? '').localeCompare(b?.name ?? ''));

    return [...videoItems, ...otherItems].map((test: Test, index: number) =>
      renderCard(test, index),
    );
  }, [studentsPlanMap, selectedStudent, renderCard]);

  // Memoized function to check if homework is active and not expired
  const isHomeworkActiveAndNotExpired = useCallback((homework: Homework) => {
    const expiryDate = moment(homework.expiryDate);
    const currentDate = moment();
    const isExpired = expiryDate.isBefore(currentDate);
    return homework.isActive && !isExpired;
  }, []);

  // Memoized function to render homework items
  const renderHomeworkItems = useCallback(() => {
    if (!selectedStudent?.classschool?.homeworks?.length) return null;

    const hasActiveHomework = selectedStudent.classschool.homeworks.some(
      isHomeworkActiveAndNotExpired,
    );

    return (
      <div className="pt-2">
        {hasActiveHomework && (
          <Typography
            color="textSecondary"
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              marginBottom: 20,
              color: 'red',
            }}
            className="row text-custom-grey flex-shrink-1 justify-content-start align-items-start align-content-start mt-1">
            TEACHER HOMEWORK
          </Typography>
        )}

        {selectedStudent.classschool.homeworks.map((homework: Homework) => {
          const expiryDate = moment(homework.expiryDate);
          const currentDate = moment();
          const isExpired = expiryDate.isBefore(currentDate);
          const highestScore = homework.answers.length
            ? Math.max(...homework.answers.map((answer: any) => answer.score))
            : 0;

          if (!homework.isActive || isExpired) return null;

          return (
            <div
              key={homework.id}
              className="row w-100 mb-2 homework-background">
              <div className="col-md-6 col-12 py-2">
                <div className="row align-items-center m-1">
                  <div className="col-11 d-flex flex-column align-items-start justify-content-center">
                    <p className="mb-0 text-custom-grey">
                      Homework date:{' '}
                      <span className="progress-value">
                        {formatDate(homework.question.createdAt)}
                      </span>
                    </p>
                    <p className="mb-0 text-custom-grey">
                      Homework Expiration date:{' '}
                      <span className="progress-value text-danger ">
                        {formatDate(homework.expiryDate)}
                      </span>
                    </p>
                    <p className="mb-0">
                      Press the button and answer the teacher's homework.
                    </p>
                  </div>
                  <div className="col-1 d-flex align-items-center justify-content-center">
                    {homework.answers.length > 0 && (
                      <img
                        src={checkMark}
                        className="img-fluid mx-auto"
                        alt="Completed"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-6 py-2 d-flex justify-content-md-center justify-content-start align-items-center">
                <div className="text-center">
                  <p className="mb-0 fs-6">Trials {homework.answers.length}</p>
                  <p className="mb-0 fs-6">Score: {highestScore}</p>
                  {isExpired && (
                    <p className="text-danger fs-7 fw-bolder mb-0">EXPIRED</p>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-6 px-0 pe-3 d-flex align-items-center justify-content-center homework-background">
                <Button
                  className="btn-record rounded-0"
                  disabled={homework.answers.length >= 3 || isExpired}
                  onClick={() => {
                    setSelectedHomework(homework);
                    setModalVisibility('openHomeworkModal', true);
                  }}>
                  <span>Answer question</span>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [
    selectedStudent,
    formatDate,
    isHomeworkActiveAndNotExpired,
    setModalVisibility,
  ]);

  // Memoized handler for AI question card click
  const handleAIQuestionClick = useCallback(async () => {
    const createdAt = moment(todayRandomExercise?.createdAt);
    const today = moment();

    if (!todayRandomExercise || !today?.isSame(createdAt, 'days')) {
      await dispatch(generateRandomExBasedOnWeakSyllables());
    } else {
      dispatch(doSelectQuestion(todayRandomExercise));
    }

    setModalVisibility('openExerciseModal', true);
  }, [todayRandomExercise, dispatch, setModalVisibility]);

  // Memoized AI question card
  const renderAIQuestionCard = useCallback(() => {
    const isBlurred =
      selectedEnrollment?.package?.isFree ||
      selectedEnrollment?.paymentStatus !== Status.DONE;

    return (
      <QuestionCard
        isBlur={isBlurred}
        question={openAIQuestionAsset}
        index={10}
        onClick={handleAIQuestionClick}
      />
    );
  }, [selectedEnrollment, handleAIQuestionClick]);

  return (
    <div className="min-vh-100 d-flex flex-column">
      {!openExerciseModal && !openTestModal && <ResponsiveAppBar />}
      <div className="flex-grow-1 d-flex flex-column">
        <ReportsHeader selectedStudent={selectedStudent} />
        <div className="container">
          {selectedStudent?.classschool?.homeworks?.length !== 0 &&
            renderHomeworkItems()}
          <div className="container w-100" id="cards_landscape_wrap-2">
            <div className="row gx-6 justify-content-center justify-content-md-between align-items-center p-2">
              {isEnrollmentReady() && renderAIQuestionCard()}
              {isEnrollmentReady() && renderTodayPlan()}
            </div>
          </div>
        </div>
        {isLoading && <Loader title={'Loading...'} />}
        {isError && <Title>Exercises fetching error, try again ⚠️</Title>}
      </div>

      <FooterBar />

      {openExerciseModal && (
        <ExerciseModal
          closeModal={() => setModalVisibility('openExerciseModal', false)}
        />
      )}
      {openTestModal && (
        <TestModal
          onQuestionsClick={() => {
            setModalVisibility('openExerciseModal', true);
            setModalVisibility('openTestModal', false);
          }}
          closeModal={() => setModalVisibility('openTestModal', false)}
        />
      )}
      {openHomeworkModal && (
        <HomeworkModal
          homework={selectedHomework}
          closeModal={() => {
            setModalVisibility('openTestModal', false);
            setModalVisibility('openHomeworkModal', false);
          }}
        />
      )}
      {isProgressModalVisible && (
        <ProgressModal
          onCloseProgressModal={() => setIsProgressModalVisible(false)}
        />
      )}
    </div>
  );
};
