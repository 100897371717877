import PlayIcon from '../../assets/startbutton.svg';
import React, {useRef, useState} from 'react';
import {getFileUrlByKey} from '../../utils/Storage';

type AudioImageType = {
  isPaused: boolean;
  imageSrc: string;
  audioSrc: string;
  onImageClick: () => void;
  style?: any;
};
export const AudioImage = ({
  isPaused,
  imageSrc,
  audioSrc,
  onImageClick,
  style = {},
}: AudioImageType) => {
  const audioRef = useRef();
  console.log('audioSrc,audioSrc', audioSrc);
  const [img, setImg] = useState<string | undefined | null>(imageSrc);

  const fetchImage = async () => {
    if (imageSrc) {
      const pathContent = imageSrc?.split('/');
      console.log('pathContent', pathContent);

      const bucketName = pathContent[3];
      let uri = imageSrc;
      console.log('bucketName11', bucketName);

      try {
        const pathVideo = imageSrc?.split('.pikado.io/');
        console.log('thumbnailUri00', pathVideo);

        const videoProfileUrl = await getFileUrlByKey(
          pathVideo[1],
          bucketName,
        ).catch(() => {
          setImg(imageSrc);
        });

        if (videoProfileUrl?.url) {
          uri = videoProfileUrl.url.toJSON();
          setImg(uri);
        }

        // console.log('uri33', uri);
      } catch (error) {
        console.error('ERRRR:->', error);
      }
    }
  };

  return (
    <div style={{position: 'relative', display: 'inline', top: 0, left: 0}}>
      {audioSrc && (
        <audio
          // @ts-ignore
          ref={audioRef}
          style={{display: 'none'}}
          id="audio"
          controls
          src={audioSrc}
        />
      )}
      <img
        onClick={() => {
          console.log('img clicked');
          if (isPaused && audioSrc) {
            onImageClick();
            // @ts-ignore
            audioRef?.current?.play();
          } else {
            onImageClick();
            if (audioSrc) {
              // @ts-ignore
              audioRef?.current.pause();
            }
          }
        }}
        style={{
          height: '20%',
          position: 'relative',
          top: 0,
          left: 0,
          ...style,
        }}
        onError={fetchImage}
        className="img-fluid img_course_model"
        src={img ?? ''}
        alt="loading..."
      />
      {isPaused && imageSrc && audioSrc && (
        <img
          src={PlayIcon}
          style={{position: 'absolute', top: '50%', left: '50%'}}
        />
      )}
    </div>
  );
};
