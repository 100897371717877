import React, {useEffect, useRef, useState} from 'react';
import {getFileUrlByKey} from '../../utils/Storage';

type ProfilePictureCardProps = {
  thumbnailUri: string | undefined | null;
  fileUri: string | undefined | null;
};

export default function RenderVideo({
  thumbnailUri,
  fileUri,
}: ProfilePictureCardProps) {
  const videoPlayerRef: any = useRef();
  const [img, setImg] = useState<string | undefined | null>(thumbnailUri);
  const [fileVideoUrl, setFileVideoUrl] = useState<string | undefined | null>(
    fileUri,
  );

  const fetchVideoUrl = async () => {
    console.log('SDSDDS', thumbnailUri);
    if (thumbnailUri) {
      const pathContent = thumbnailUri.split('/');
      const bucketName = pathContent[3];
      let uri = thumbnailUri;
      console.log('bucketName', bucketName);
      console.log('proPicPath', pathContent);

      try {
        const pathVideo = thumbnailUri?.split('.pikado.io/');
        console.log('thumbnailUri00', pathVideo);

        const videoProfileUrl = await getFileUrlByKey(
          pathVideo[1],
          bucketName,
        ).catch(() => {
          setImg(thumbnailUri);
        });

        if (videoProfileUrl?.url) {
          uri = videoProfileUrl.url.toJSON();
          setImg(uri);
        }
      } catch (error) {
        console.error('ERRRR:->', error);
      }
    }

    if (fileUri) {
      const pathVideo = fileUri?.split('.pikado.io/');
      const pathContent = fileUri.split('/');
      const bucketName = pathContent[3];

      let uri = fileUri;
      const videoProfileUrl = await getFileUrlByKey(
        pathVideo[1],
        bucketName,
      ).catch(() => {
        setFileVideoUrl(fileUri);
      });

      if (videoProfileUrl?.url) {
        uri = videoProfileUrl.url.toJSON();
        setFileVideoUrl(uri);
      }

      setTimeout(() => {
        videoPlayerRef?.current?.play();
      }, 1000);
    }
  };

  useEffect(() => {
    fetchVideoUrl();
  }, []);

  return (
    <div style={{display: 'inline-block', padding: 0}}>
      <video
        controlsList="nodownload"
        controls={true}
        onClick={() => {
          videoPlayerRef?.current?.paused
            ? videoPlayerRef?.current?.pause()
            : videoPlayerRef?.current?.play();
        }}
        ref={videoPlayerRef}
        src={fileVideoUrl ?? ''}
        poster={img ?? ''}
        style={{width: '100%', resize: 'vertical', display: 'flex'}}
        height={'60%'}
      />
    </div>
  );
}
