// import { rootReducer } from './../reducers/index';
import {AuthenticationService} from '../../lib/services';
import {
  GET_USER,
  IS_LOGGED_IN,
  RESET_STUDENT_STATE,
  SIGN_IN_SUCCESS,
  SIGN_IN__WITH_PARTNER_SUCCESS,
  SIGN_OUT_ERROR,
  SIGN_UP_SUCCESS,
  UPDATE_USER_ATTRIBUTES,
} from '../../constants';
import {createUser, getUser} from './user';
import type {PartnerUser} from '../reducers/auth';
import {Amplify} from 'aws-amplify';
import awsmobile from '../../amplifyconfiguration.json';

import {
  fetchStudents,
  setSelectedStudent,
  fetchSelectedStudent,
} from './student';
import {fetchProgramsDetails} from './program';
import {isLoadingLogInRequest, isLoadingRequest} from './appState';
import {store} from '../store';
import {
  AuthenticatorResponse,
  PikaDoPartners,
  User,
  UserService,
  UserUpdate,
} from '../../lib';
import {getStudentTodayPlan} from './enrollment';
import {Alert, notification} from 'antd';
import {decodeToken} from 'react-jwt';
import {
  ConfirmUserAttributeInput,
  FetchUserAttributesOutput,
  ResetPasswordOutput,
  SignInOutput,
  SignUpOutput,
  VerifiableUserAttributeKey,
  confirmResetPassword,
  confirmUserAttribute,
  fetchUserAttributes,
  rememberDevice,
  resetPassword,
  sendUserAttributeVerificationCode,
  // deleteUser,
} from 'aws-amplify/auth';
import Swal from 'sweetalert2';
// import {rootReducer} from '../reducers';
store.subscribe(getPartnerAuthToken);

const authenticationService = new AuthenticationService();
const userService = new UserService();
export const setSignInPartner =
  (partUser: PartnerUser) => async (dispatch: any) => {
    console.log('from actions ->', partUser);
    try {
      dispatch({type: SIGN_IN__WITH_PARTNER_SUCCESS, payload: partUser});
    } catch (error) {
      console.log('SIGN_IN_WITH_PARTNER_SUCCESS error: ', error);
    }
  };

export const doSignIn =
  (email: string, password: string) => async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    configAmplifyCognitoRequest();

    try {
      let response = await authenticationService.signIn(email, password);
      const authData = await fetchUserAttributes();
      dispatch(getUser());

      await dispatch(fetchStudents());
      const studentsDetails = getState().student.studentsDetails;
      await dispatch(setSelectedStudent(studentsDetails[0]));
      await dispatch(fetchSelectedStudent());
      await dispatch(fetchProgramsDetails());
      await dispatch(getStudentTodayPlan());
      dispatch({type: SIGN_IN_SUCCESS, payload: authData});
      return response;
    } catch (error: any) {
      console.log('SIGN_IN_ERROR1', error.message);
      //failed login or registration
      if (error.message == 'There is already a signed in user.') {
        dispatch(doSignOut());
        dispatch(isLoadingRequest(false, error));
        return dispatch(doSignIn(email, password)); // re-login
      } else if (
        // failed create user and student operation , just registered email in cognito
        error.message == "Cannot read properties of undefined (reading 'id')"
      ) {
        try {
          // await deleteUser(); // this user is registered but didn't continue registration process
        } catch (e) {
          console.log(e);
        }
      } else {
        dispatch(isLoadingRequest(false, error));
        Swal.fire(error.message);
        return null;
      }
    } finally {
      dispatch(isLoadingRequest(false));
    }
  };

export const doSignOut = () => async (dispatch: any) => {
  try {
    dispatch({type: 'SIGN_OUT'});
    await authenticationService.signOut();
    dispatch({type: 'SIGN_OUT_SUCCESS'});
    dispatch({type: 'RESET_USER'});
    dispatch({type: RESET_STUDENT_STATE});
  } catch (error) {
    dispatch({type: SIGN_OUT_ERROR, payload: error});
    dispatch({type: 'RESET_USER'});
    dispatch({type: RESET_STUDENT_STATE});
  }
};

export const doSignInWithPartner =
  (navState: any, partnerAppName: PikaDoPartners) =>
  async (dispatch: any, getState: any) => {
    try {
      console.log('navState.url->', navState.url);

      const arrState = navState.url.split('?');
      console.log('arrState>1', arrState);

      const tokenFounded = arrState?.find((value: string) =>
        value.startsWith('token:'),
      );

      console.log('tokenFounded', tokenFounded);

      const token = tokenFounded?.split(':')[1];

      console.log('token', token);

      const myDecodedToken: any = decodeToken(token);

      let partnerId = myDecodedToken?.student_id;

      console.log('studentIdFounded', myDecodedToken);

      if (arrState && partnerId) {
        dispatch(isLoadingLogInRequest(true));
        configAmplifyLambdaRequest();
        const resp: AuthenticatorResponse =
          await authenticationService.validatePartnerAuthUser(
            partnerAppName,
            partnerId,
          );
        const myDecodedTokenAuth: any = decodeToken(resp?.token ?? '');

        const partUser: PartnerUser = {
          partnerAppName,
          sharedStudentId: partnerId,
          isAuthenticated: true,
          cognitoUserPartnerId: myDecodedTokenAuth?.user?.cognitoUserId,
          token: resp.token,
        };
        dispatch(setSignInPartner(partUser));

        configAmplifyLambdaRequest();
        /**
         * We need to add rememberDevice(), to keep the auth session on the user's device.
         * Without it the user will logout after any refresh.
         * We commented it, as when it's active the auth. will fail and the user cannot log in.
         * */
        // await rememberDevice();

        await dispatch(getUser());

        console.log('partUser', partUser);

        await dispatch(fetchStudents());
        const studentsDetails = getState().student.studentsDetails;
        await dispatch(setSelectedStudent(studentsDetails[0]));
        await dispatch(fetchSelectedStudent());
        await dispatch(fetchProgramsDetails());
        await dispatch(getStudentTodayPlan());

        dispatch({type: IS_LOGGED_IN, payload: true});
      }
      console.log(arrState);

      dispatch(isLoadingLogInRequest(false));
    } catch (error: any) {
      dispatch(doSignOut());
      console.log('SIGN_IN_ERROR2', error);
      setTimeout(() => {
        window.location.href = '/';
      }, 3200);

      dispatch(isLoadingLogInRequest(false, error));

      notification.error({
        placement: 'top',
        duration: 10,
        message: error.errors[0].message,
      });
    }
  };

export const doSignUp =
  (email: string, password: string, country: string, whatsappNumber: string) =>
  async (dispatch: any, getState: any) => {
    configAmplifyCognitoRequest();
    dispatch(isLoadingRequest(true));

    try {
      let signUpRes: SignUpOutput | any = await authenticationService.signUp(
        email,
        password,
        whatsappNumber,
      );
      await authenticationService.signIn(email, password);
      await dispatch(createUser(signUpRes?.userId, country, whatsappNumber));
      dispatch({type: SIGN_UP_SUCCESS});
      dispatch(isLoadingRequest(false));
      await sendUserAttributeVerificationCode({userAttributeKey: 'email'});
      return true;
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        text: error,
      });
      dispatch(isLoadingRequest(false, error));
      return false;
    }
  };

function selectPartnerUser(state: any): PartnerUser {
  return state.auth.partnerUser;
}

export function getPartnerAuthToken(): string | undefined {
  let partnerUsr: PartnerUser = selectPartnerUser(store.getState());
  const authToken: string =
    //@ts-ignore
    'partner_auth-' + partnerUsr?.token ?? partnerUsr?.partnerAppName;

  return partnerUsr?.isAuthenticated ? authToken : undefined;
}

export function getPartnerCognitoId(): string {
  let partnerUsr: PartnerUser = selectPartnerUser(store.getState());
  const partnerCognitoId: string = partnerUsr?.cognitoUserPartnerId ?? '';

  return partnerUsr?.isAuthenticated ? partnerCognitoId : '';
}

export function configAmplifyLambdaRequest() {
  Amplify.configure({
    ...awsmobile,
    auth: {
      //@ts-ignore
      type: 'CUSTOM_AUTH',
      jwtToken: getPartnerAuthToken(),
    },
    aws_appsync_authenticationType: 'AWS_LAMBDA',
  });
}

export function configAmplifyCognitoRequest() {
  Amplify.configure({
    ...awsmobile,
  });
}

function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
  const {nextStep} = output;
  switch (nextStep.resetPasswordStep) {
    case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
      const codeDeliveryDetails = nextStep.codeDeliveryDetails;
      Swal.fire(
        `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,
      );
      console.log(
        `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,
      );
      // Collect the confirmation code from the user and pass to confirmResetPassword.
      break;
    case 'DONE':
      console.log('Successfully reset password.');
      break;
  }
}
export const doForgetPassword =
  (username: string) => async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    try {
      const output = await resetPassword({username});
      dispatch(isLoadingRequest(false));

      handleResetPasswordNextSteps(output);
      return true;
    } catch (error: any) {
      console.log('doForgetPassword error', error);
      Swal.fire({
        icon: 'error',
        text: error,
      });
      dispatch(isLoadingRequest(false, error));
      console.log('resendSignUp', error);
      return false;
    }
  };
export const fogetPasswordSubmitCode =
  (userName: string, code: string, newPassword: string) =>
  async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    try {
      await confirmResetPassword({
        username: userName,
        confirmationCode: code,
        newPassword,
      });

      dispatch(isLoadingRequest(false));

      Swal.fire('Successfully Reset Password');
    } catch (error: any) {
      console.log('fogetPasswordSubmitCode error', error);
      Swal.fire({
        icon: 'error',
        text: error,
      });
      dispatch(isLoadingRequest(false, error));
    }
  };
export const setUserAttributes = (usrAttrubitues: any) => {
  return {type: UPDATE_USER_ATTRIBUTES, payload: usrAttrubitues};
};
export const updateAuthInfo = () => async (dispatch: any) => {
  const auth: FetchUserAttributesOutput = await fetchUserAttributes();
  dispatch(setUserAttributes(auth));
};
export const sendVerificationEmailCode =
  () => async (dispatch: any, getState: any) => {
    try {
      const au = await authenticationService.sendVerficationCodeUserEmail();
    } catch (error: any) {
      console.log('sendVerificationEmailCode in auth actions error =>', error);
      dispatch(isLoadingRequest(false, error));
    }
  };

export const doUpdateCognitoUser =
  (phoneNumber?: string, email?: string) =>
  async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    try {
      console.log('Authent phoneNumber', phoneNumber);
      const response = await authenticationService.updateCognitoUser(
        phoneNumber,
        email,
      );
      console.log('RESSSS', response);
      await dispatch(updateAuthInfo());

      dispatch(isLoadingRequest(false));

      if (phoneNumber) {
        // navigate(routNames.VERIFY_PHONE_NUM_SCREEN);
      }
    } catch (error: any) {
      // crashlytics().recordError(error);
      console.log('error', error);
      await dispatch(updateAuthInfo());

      dispatch(isLoadingRequest(false, error));

      // const resp = await Auth.confirmSignUp(userName);
      console.log('update Cognito User eror', error);
      // Alert.alert(JSON.stringify(error));
    }
  };

export const submitVerificationCode =
  (
    attrName: VerifiableUserAttributeKey = 'email',
    code: string,
    phoneNumber?: string,
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));
    try {
      const inputConfirmAttr: ConfirmUserAttributeInput = {
        // attrName,
        // code,
        userAttributeKey: attrName,
        confirmationCode: code,
      };
      await confirmUserAttribute(inputConfirmAttr);
      await dispatch(updateAuthInfo());
      const authData = await fetchUserAttributes();
      console.log('authData', authData);
      dispatch({type: SIGN_IN_SUCCESS, payload: authData});
      if (attrName === 'phone_number' && phoneNumber) {
        Swal.fire('Phone Number has been successfully verified');
        await dispatch(updateUserPhoneNumber(phoneNumber));
      }
      dispatch(isLoadingRequest(false));
      return true;
    } catch (error: any) {
      console.log('error', error);
      // crashlytics().recordError(new Error(JSON.stringify(error)));
      dispatch(isLoadingRequest(false, error));
      return false;
    }
  };

export const updateUserPhoneNumber =
  (phoneNumber: string) => async (dispatch: any, getState: any) => {
    try {
      const user: User = getState().user.user;
      const userId = user?.id;
      const updatedAt = user?.updatedAt;
      const usrUpdate: UserUpdate = {
        whatsappNumber: phoneNumber,
      };
      //update phone number in dynmo
      const userResp = await userService.updateUser(
        userId,
        updatedAt,
        usrUpdate,
      );

      dispatch({type: GET_USER, payload: userResp});
    } catch (error) {
      // crashlytics().recordError(error);
      console.log('ERRRORRS_ENTER_UPDATE33333', error);
    }
  };
